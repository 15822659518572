
















































































import {Component, Vue} from "vue-property-decorator";
import BlogLabelService from "@/services/BlogLabelService";
import BlogLabel from "@/models/BlogLabel";
import Family from "@/models/Family";
import FamilyService from "@/services/FamilyService";


@Component
export default class HeaderComponent extends Vue {
    value: number = 1
    drawer = null
    blogMenuLabels: any = [{title: "Todas", url: "/noticias"}]
    blogLabels: BlogLabel[] = []
    families: Family[] = []
    familiesMenu: any = []

    buttons = [
        { title: "Centro", url: "", submenus: [{title: "El Centro", url:"/centro"}, {title: "Equipo Directivo", url: "/equipo-directivo"}, {title: "Documentos Institucionales", url:"/documentos-institucionales"}] },
        { title: "Departamentos", url: "/departamentos" },
        // { title: "Ciclos Formativos", url: "" },
        { title: "Secretaría Virtual", url: "/secretaria" },
        { title: "Proyectos", url: "/proyectos" },
        { title: "Familias", submenus: this.familiesMenu},
        { title: "Noticias", url: "/noticias", submenus: this.blogMenuLabels }
    ]

    items = [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' },
    ]


    created() {
        this.getBlogLabels()
        this.getFamilies()
    }

    redirectSubmenu(url: string, externalUrl: string | null) {
        if (externalUrl) {
            window.open(externalUrl, "_blank")
        } else {
            this.$router.push(url)
        }
    }

    redirect(url: string, submenu: object | undefined) {
        if (submenu) {
            return
        } else {
            this.$router.push(url)
        }
    }

    async getFamilies() {
        await FamilyService.getFamilies(this, this.families)
        this.families.forEach(v => {
            this.familiesMenu.push({
                title: v.title,
                url: "/familia/" + v.id,
                external: v.url
            })
        })
    }

    async getBlogLabels() {
        await BlogLabelService.getBlogLabels(this, this.blogLabels)
        this.blogLabels.forEach(v => {
            this.blogMenuLabels.push({
                title: v.title,
                url: `/noticias/?q=${v.title}`
            })
        })
    }
}



























import {Component, Vue} from "vue-property-decorator";
import MetaInfo from "vue-meta"

@Component({
    metaInfo(): MetaInfo {
        return {
            title: "IES Europa",
            htmlAttrs: {
                lang: 'es'
            },
            meta: [
                {charset: 'utf-8'},
                {
                    name: 'description',
                    content: 'Las Redes Sociales y la Web de IES Europa están siempre informando'
                },
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {property: 'og:title', content: "Redes Sociales - IES Europa"},
                {property: 'og:site_name', content: 'IES Europa'},
                {property: 'og:type', content: 'section'}
            ]
        };
    }
})
export default class FooterComponent extends Vue {
    count: number = 0
    icons = [
        {
            icon: "mdi-facebook",
            url: "https://www.facebook.com/IES-EUROPA-203673183555320/"
        },
        {
            icon: "mdi-twitter",
            url: "https://twitter.com/europa_aguilas?lang=es"
        },
        {
            icon: "mdi-telegram",
            url: ""
        },
        {
            icon: "mdi-instagram",
            url: "https://www.instagram.com/ieseuropa/?hl=es"
        }
    ]
    footerContent = ""

    redirect(dir : string) {
        if (dir.length>1) window.open(dir, "_blank")
    }


    login() {
        this.count++
        console.log(this.count)
        if (this.count >= 3) {
            this.count = 0
            this.$router.push("/login")
        }
    }
}















import {Component, Vue, Watch} from "vue-property-decorator";
import SnackbarModule from "@/store/SnackbarModule";
import {getModule} from "vuex-module-decorators";

@Component
export default class SnackbarComponent extends Vue {

    snackbarModule: SnackbarModule = getModule(SnackbarModule);

    get enabled() {
        return this.snackbarModule.enabled
    }

    set enabled(v: boolean) {
        this.snackbarModule.setSnackbarEnabled(v)
    }

    @Watch('enabled')
    onChildChanged(val: string) {
        if (!val) {
            this.snackbarModule.checkQueueMessages()
        }
    }

}
